/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.menu-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  transition: margin .5s;
  margin: 0 0 -240px 0;
}

.menu-wrapper.is-nav-open {
  margin-bottom: 0;
}

.nav {
  position: relative;
  /*height: 240px;*/
  width: 100%;
  
}


.ui.modal.infoModal {
  top: 5% !important;
  max-height: 40% !important;
}

.grid div {
  box-shadow: none;
}

.ui.modal {
  top: 5% !important;
}

body {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.unselectable {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

#root {
  width: 2564px !important;
  margin: 0px;
}
.App {
  width: 2560px !important;
}

body {
  width: 2560px !important;
}

.ui.toggle.checkbox .box, .ui.toggle.checkbox label {
  color: rgba(0,0,0,0.6) !important;
  font-weight: 700;
}

.icon {
  color: #2185d0;
}

i.stop.icon {
  color: white;
}

i.warning.icon {
  color: red;
}

i.close.icon {
  color: black;
}


.middleware-statuses {
  position: fixed;
  top: 310px;
  left: 20px;
  width: 85px;
  font-size: 10;
}